// 空对象模板
export default {
    // 用户信息输入
    userInput: {
        name: "",
        gender: 0,
        passwd: "",
        phone: "",
        email: "",
        avatar: "",
        introduction: "",
        code: "",
        token: ""
    },
    // 通用弹框输入
    dialogInput: {
        name: "",
        gender: 0,
        passwd: "",
        phone: "",
        email: "",
        avatar: "",
        introduction: "",
        code: "",
        token: ""
    },
    // 用户界面通用弹框配置
    userDialog: {
        visible: false,
        title: "",
        id: null,
        codeStr:"验证码",
        pwdFlag: 1,
        type: {
            findPwd: {
                id: 1,
                title: "找回密码",
                flag: 1,
            },
            getCode: {
                id: 2,
                title: "获取验证码"
            },
            changePhone: {
                id: 3,
                title: "修改手机号"
            },
            bindPhone: {
                id: 4,
                title: "绑定手机号"
            },
            changeEmail: {
                id: 5,
                title: "修改邮箱"
            },
            bindEmail: {
                id: 6,
                title: "绑定邮箱"
            },
            changeAvatar: {
                id: 7,
                title: "修改头像"
            }
        }
    },
    // 用户信息
    user: {
        id: null,
        name: "",
        account: "",
        phone: "",
        email: "",
        avatar: "",
        gender: 0,
        introduction: "",
    },
}