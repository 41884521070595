import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    toolbar: {
      visible: false,
      enter: true
    },
    user: { },                     // 用户
    webInfo: {
      name: "字节幺零二四",      // 网站名字
      icon: "",                 // 头像
      title: [],                // 网站标题
      noticeList: [],           // 公告
      footer: "",           // 页脚诗句
      bg: "",               // 背景图片
    }
  },
  getters: {
    navigationBar: state => {
      if (state.sortInfo !== null && state.sortInfo.length !== 0) {
        return state.sortInfo.filter(f => f.sortType === 0);
      } else {
        return [];
      }
    }
  },
  mutations: {
    // 导航栏是否可见
    changeToolbarStatus(state, toolbarState) {
      state.toolbar = toolbarState;
    },
    // 保存网站信息
    loadWebInfo(state, webInfo) {
      webInfo.title = webInfo.title.split('');
      state.webInfo = webInfo;
    },
    // 保存用户
    loadUser(state,user){
      state.user = user;
    }
  },
  actions: {},
  modules: {},
  plugins: [
    // 持久化
    createPersistedState({
      storage: window.localStorage
    })
  ]
})
