import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()


const routes = [
    {
        path: '/',
        component: () => import('@/views/web'),
        children: [
            {
                path: "/",
                name: "index",
                component: () => import('@/views/')
            },
            {
                path: "/article/:id",
                name: "article",
                component: () => import('@/views/article')
            },
                  {
                path: "/user",
                name: "user",
                component: () => import('@/views/user')
            },
            // {
            //     path: "/weiYan",
            //     name: "weiYan",
            //     component: () => import('../components/weiYan')
            // },
            // {
            //     path: "/love",
            //     name: "love",
            //     component: () => import('../components/love')
            // },
            // {
            //     path: "/favorite",
            //     name: "favorite",
            //     component: () => import('../components/favorite')
            // },
            // {
            //     path: "/message",
            //     name: "message",
            //     component: () => import('../components/message')
            // },
            // {
            //     path: "/friend",
            //     name: "friend",
            //     component: () => import('../components/friend')
            // },
            // {
            //     path: "/funny",
            //     name: "funny",
            //     component: () => import('../components/funny')
            // },
            // {
            //     path: "/about",
            //     name: "about",
            //     component: () => import('../components/about')
            // },
    
            // {
            //     path: "/letter",
            //     name: "letter",
            //     component: () => import('../components/letter')
            // }
        ]
    },
    // {
    //     path: '/verify',
    //     name: 'verify',
    //     component: () => import('@/views/verify')
    // }
]

const router = createRouter({
    history: routerHistory,
    routes
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!Boolean(localStorage.getItem("token"))) {
//       next({
//         path: '/verify',
//         query: { redirect: to.fullPath }
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// })

export default router;