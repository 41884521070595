import axios from "axios";
import cfg from "@/config/dev.js";
import { ElMessage } from 'element-plus'
//处理url参数
// import qs from "qs";

// import store from "../store";

// 基础url
// axios.defaults.baseURL = constant.baseURL;

// 创建axios实例
const service = axios.create({
  baseURL: cfg.baseURL,
  timeout: cfg.timeout
})

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  response=>{
    if(response.data.code==200){
      return response.data
    }else{
      ElMessage({
        message: response.data.msg,
        type: 'warning',
      })
      return Promise.reject(new Error(response.data.msg));
    }
  },
  error=>{
    // 对响应错误做点什么
    ElMessage({
      message: "网络错误",
      type: 'warning',
    })
    return Promise.reject(error);
  }
  
  // if (response.code === 300) {
  //   store.commit("loadCurrentUser", {});
  //   localStorage.removeItem("userToken");
  //   store.commit("loadCurrentAdmin", {});
  //   localStorage.removeItem("adminToken");
  //   window.location.href = constant.webURL + "/user";
  // }
);

export default service

// 当data为URLSearchParams对象时设置为application/x-www-form-urlencoded;charset=utf-8
// 当data为普通对象时，会被设置为application/json;charset=utf-8


// export default {
//   post(url, params = {}, isAdmin = false, json = true) {
//     let config;
//     if (isAdmin) {
//       config = {
//         headers: { "Authorization": localStorage.getItem("adminToken") }
//       };
//     }
//     else {
//       config = {
//         headers: { "Authorization": localStorage.getItem("userToken") }
//       };
//     }

//     return new Promise((resolve, reject) => {
//       // qs.stringify()将对象序列化成URL的形式，并以&进行拼接
//       axios
//         .post(url, json ? params : qs.stringify(params), config)
//         .then(res => {
//           resolve(res.data);
//         })
//         .catch(err => {
//           reject(err);
//         });
//     });
//   },

//   get(url, params = {}, isAdmin = false) {
//     let headers;
//     if (isAdmin) {
//       headers = { "Authorization": localStorage.getItem("adminToken") };
//     }
//     else {
//       headers = { "Authorization": localStorage.getItem("userToken") };
//     }

//     return new Promise((resolve, reject) => {
//       axios.get(url, {
//         params: params,
//         headers: headers
//       }).then(res => {
//         resolve(res.data);
//       }).catch(err => {
//         reject(err)
//       })
//     });
//   },


//   upload(url, param, isAdmin = false) {
//     let config;
//     if (isAdmin) {
//       config = {
//         headers: { "Authorization": localStorage.getItem("adminToken"), "Content-Type": "multipart/form-data" }
//       };
//     } else {
//       config = {
//         headers: { "Authorization": localStorage.getItem("userToken"), "Content-Type": "multipart/form-data" }
//       };
//     }
//     return new Promise((resolve, reject) => {
//       axios
//         .post(url, param, config)
//         .then(res => {
//           resolve(res.data);
//         })
//         .catch(err => {
//           reject(err);
//         });
//     });
//   },

//   uploadQiniu(url, param) {
//     let config = {
//       headers: { "Content-Type": "multipart/form-data" },
//       timeout: 60000
//     };

//     return new Promise((resolve, reject) => {
//       axios
//         .post(url, param, config)
//         .then(res => {
//           resolve(res.data);
//         })
//         .catch(err => {
//           reject(err);
//         });
//     });
//   }

