// 开发环境配置
// export default {
//     // 后端项目地址
//     baseURL: "http://localhost:11000",
//     // 文件上传地址
//     uploadUrl:"http://localhost:11000/api/blog/resource/upload",
//     // 网络资源前缀
//     urlPrefix:"http://47.103.67.98:9000/blog/",
//     // 连接超时时间
//     timeout: 5000
// }
// 运行环境配置
export default {
    // 后端项目地址
    baseURL: "http://api.zijie1024.cn",
    // 文件上传地址
    uploadUrl:"http://api.zijie1024.cn/api/blog/resource/upload",
    // 网络资源前缀
    urlPrefix:"http://resource.zijie1024.cn/blog/",
    // 连接超时时间
    timeout: 5000
}
