import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import mavonEditor from 'mavon-editor'

import http from '@/utils/request'
import common from '@/utils/common'
import constant from '@/utils/constant'
import cfg from '@/config/dev'
import sim from '@/config/simple'

// 引入css
import 'element-plus/dist/index.css'
import '@/assets/css/animation.css'
import '@/assets/css/index.css'
import '@/assets/css/color.css'
import '@/assets/css/markdown-highlight.css'
import '@/assets/css/font-awesome.min.css'
import '@/assets/css/tocbot.css'
import 'mavon-editor/dist/css/index.css'


const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(mavonEditor)

// Vue原型上添加属性
app.config.globalProperties.$http = http
app.config.globalProperties.$common = common
app.config.globalProperties.$constant = constant
app.config.globalProperties.$cfg = cfg
app.config.globalProperties.$sim = sim

app.config.productionTip = false

// 注册图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')